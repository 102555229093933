import React from 'react';
import { Contact } from '../types';
import ContactCard from './ContactCard';

interface ContactListProps {
  contacts: Contact[];
  selectedContactId: string | null;
  onContactSelect: (contact: Contact) => void;
  onToggleStar: (contactId: string) => void;
}

const ContactList: React.FC<ContactListProps> = ({
  contacts,
  selectedContactId,
  onContactSelect,
  onToggleStar,
}) => {
  return (
    <div className="flex-1 overflow-y-auto">
      {contacts.map((contact) => (
        <ContactCard
          key={contact.id}
          contact={contact}
          isSelected={contact.id === selectedContactId}
          onClick={() => onContactSelect(contact)}
          onToggleStar={() => onToggleStar(contact.id)}
        />
      ))}
    </div>
  );
};

export default ContactList;