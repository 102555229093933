import { useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Contact, Message } from "../pages/sms/types";
import { getSMSContacts, getSMSMessages, sendMessageToContact } from "../services/smsService";


const useSMS = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const searchContacts = useCallback(
    async (
      query: string,
    ) => {
      setLoadingContacts(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const fetchedContacts = await getSMSContacts(
          query,
          accessToken,
        );
        setContacts(fetchedContacts);
      } catch (err) {
        setError("Failed to fetch SMS contacts");
      } finally {
        setLoadingContacts(false);
      }
    },
    []
  );

  const getMessages = useCallback(
    async (contactId: string, showLoading: boolean = true) => {
      if (showLoading) {
        setLoadingMessages(true);
      }
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const messages = await getSMSMessages(contactId, accessToken);
        setMessages(messages);
      } catch (err) {
        setError("Failed to fetch SMS messages");
      } finally {
        if (showLoading) {
          setLoadingMessages(false);
        }
      }
    },
    []
  );

  const sendMessage = useCallback(
    async (contactId: string, message: string) => {
      setSendingMessage(true);
      try {
        const accessToken = await getAccessTokenSilently();
        const sentMessage = await sendMessageToContact(contactId, message, accessToken);
        setMessages(prev => [...prev, sentMessage]);
        return sentMessage;
      } catch (err) {
        setError("Failed to send message");
      } finally {
        setSendingMessage(false);
      }
    },
    []
  );

  return {
    searchContacts,
    contacts,
    loadingContacts,
    getMessages,
    messages,
    loadingMessages,
    sendMessage,
    sendingMessage,
    error,
  };
};


export default useSMS;
