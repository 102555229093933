import React, { FC, useEffect, useState } from "react";
import { Match } from "../../types";
import useCRM from "../../hooks/useCRM";
import { useParams } from "react-router-dom";
import constants from "../../constants";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ImageCarousel from "../../components/ImageCarousel";

const UserDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    selectedUser,
    loading,
    error,
    fetchUserDetails,
    generateMatch,
    toggleAutomatedResponse,
    modifyMatchNoteOrBio,
    archiveMatch,
    sendMatch,
    archiveUser,
    setHideNeedsMatchAlerts,
    createIntroduction,
  } = useCRM();
  const [numMessagesToShow, setNumMessagesToShow] = useState(5);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setNote] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    if (id) {
      fetchUserDetails(id);
    }
  }, [id, fetchUserDetails]);

  const handleEditClick = (match: Match) => {
    setSelectedMatch(match);
    setNote(match.personalized_matchmaker_note);
    setBio(match.prospect_bio);
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (selectedMatch) {
      await modifyMatchNoteOrBio(selectedMatch._id, note, bio);
    }
    setIsModalOpen(false);
    // Optionally, refresh the match data here
  };

  const handleDeleteClick = async (matchId: string) => {
    await archiveMatch(matchId);
    // Optionally, refresh the match data here
  };

  const handleArchiveUser = async () => {
    await archiveUser(id ?? "");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!selectedUser) return <div>User not found</div>;

  const events = Object.entries(selectedUser)
    .filter(([key, value]) => key.startsWith("event_") && value)
    .map(([key, value]) => ({
      name: key.replace("event_", "").replace(/_/g, " "),
      date: value,
    }));

  // sort events by date
  events.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">User Details</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <a href="/crm" className="underline text-blue-700 cursor-pointer">
            back
          </a>
          <h2 className="text-xl font-semibold mb-2">Basic Information</h2>
          <ImageCarousel images={selectedUser.photos} />
          <button
            onClick={() => handleArchiveUser()}
            className="text-red-500 underline p-2 rounded-md mt-4"
          >
            Archive User
          </button>

          <p>
            <strong>Name:</strong> {selectedUser.name} {selectedUser.last_name}
          </p>
          <p>
            <strong>Phone:</strong> {selectedUser.phone_number}
          </p>
          <p>
            <strong>Email:</strong> {selectedUser.email}
          </p>
          <p>
            <strong>Age:</strong> {selectedUser.computed_age}
          </p>
          <p>
            <strong>Gender:</strong> {selectedUser.gender}
          </p>
          <p>
            <strong>Signup Location:</strong> {selectedUser.signup_location}
          </p>
          <p>
            <strong>Matching Location:</strong> {selectedUser.matching_location}
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">Additional Information</h2>
          <p>
            <strong>Target Gender:</strong> {selectedUser.target_gender}
          </p>
          <p>
            <strong>Timezone:</strong> {selectedUser.timezone}
          </p>
          <p>
            <strong>Is Accepted:</strong>{" "}
            {selectedUser.is_accepted ? "Yes" : "No"}
          </p>
          
          <p>
            <strong>Automated Response Enabled:</strong>{" "}
            {selectedUser.automated_response_enabled ? "Yes" : "No"}
          </p>
          <p>
            <strong>Paying Status:</strong>{" "}
            {selectedUser.paying_status || "Not available"}
          </p>
          <p>
            <strong>Intro Tracker Link:</strong>{" "}
            <a href={selectedUser.intro_tracker_link} target="_blank" rel="noreferrer">
              {selectedUser.intro_tracker_link}
            </a>
          </p>
        </div>
        <div>
          <h2 className="text-xl font-semibold mb-2">Automated Response</h2>
          <div className="flex items-center">
            <label className="switch">
              <input
                type="checkbox"
                checked={selectedUser.automated_response_enabled}
                onChange={() =>
                  toggleAutomatedResponse(selectedUser.phone_number)
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="flex flex-col justify-center items-left">
            <h2 className="text-xl font-semibold mb-2">
              Hide Needs Match Alerts
            </h2>
            <label className="switch">
              <input
                type="checkbox"
                checked={selectedUser.hide_needs_match_alerts}
                onChange={() =>
                  setHideNeedsMatchAlerts(
                    selectedUser.phone_number,
                    !selectedUser.hide_needs_match_alerts
                  )
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
          <p className="text-sm text-red-500">
            {selectedUser.needs_match ? "Needs Match" : ""}
          </p>
        </div>
      </div>
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Events</h2>
        <ul className="list-disc pl-5">
          {events.map((event) => (
            <li key={event.name} className="flex justify-between items-center">
              <span>{event.name}</span>
              <span className="ml-4 text-sm text-gray-500">
                {new Date(event.date).toLocaleString()}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Recent Messages</h2>
        <a
          className="underline text-blue-700"
          href={`/sms`}
          target="_blank" rel="noreferrer"
        >
          Chat
        </a>{" "}
        |{" "}
        <button
          onClick={() => setNumMessagesToShow(numMessagesToShow + 5)}
          className="underline text-blue-700 cursor-pointer"
        >
          Show More
        </button>
        {selectedUser.messages && selectedUser.messages.length > 0 ? (
          <div className="space-y-2">
            {selectedUser.messages
              .slice(-numMessagesToShow)
              .map((message, index) => (
                <div
                  key={index}
                  className={`flex ${
                    message.sender === "assistant"
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  <div
                    className={`max-w-[70%] p-3 rounded-lg break-words ${
                      message.sender === "assistant"
                        ? "bg-blue-500 text-white"
                        : "bg-gray-200 text-gray-800"
                    }`}
                  >
                    <p className="whitespace-pre-wrap">{message.message}</p>
                    {message.timestamp && (
                      <p className="text-xs mt-1 opacity-75">
                        {new Date(message.timestamp).toLocaleString()}
                      </p>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <p>No recent messages</p>
        )}
      </div>
      <div className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Recent Calls</h2>
        {selectedUser.calls && selectedUser.calls.length > 0 ? (
          <ul className="list-disc pl-5">
            {selectedUser.calls.slice(-5).map((call, index) => (
              <li key={index}>
                <a
                  href={`https://beta.retellai.com/dashboard/history?history=${call.call_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  Call {index + 1}
                </a>
                <p>{call.summary}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No recent calls</p>
        )}
      </div>
      <div id="matches" className="mt-4">
        <h2 className="text-xl font-semibold mb-2">Matches</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Sent Matches</h3>
            <ul className="list-disc pl-5">
              {selectedUser.sent_matches &&
                selectedUser.sent_matches.map((match, index) => (
                  <li key={index}>
                    <a
                      href={`${constants.STAGING_SITE_URL}/profile?matchId=${match._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {match.prospect_name} (Age: {match.prospect_age})
                    </a>
                    <button
                      onClick={() => handleEditClick(match)}
                      className="underline ml-2 rounded-md text-red-800"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteClick(match._id)}
                      className="underline ml-2 rounded-md text-red-800"
                    >
                      Archive
                    </button>
                    <button
                      onClick={() => createIntroduction(match._id)}
                      className="underline ml-2 rounded-md text-blue-800"
                    >
                      Create Introduction
                    </button>
                    {match.introduction_requested
                      ? "Introduction Requested"
                      : ""}
                  </li>
                ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-2">Pending Matches</h3>
            <ul className="list-disc pl-5">
              {selectedUser.outstanding_matches &&
                selectedUser.outstanding_matches
                  .filter((match) => !match["has_been_sent"])
                  .map((match, index) => (
                    <li key={index}>
                      <a
                        href={`${constants.STAGING_SITE_URL}/profile?matchId=${match._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {match.prospect_name} (Age: {match.prospect_age})
                      </a>
                      <button
                        onClick={() => handleEditClick(match)}
                        className="underline ml-2 rounded-md text-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteClick(match._id)}
                        className="underline ml-2 rounded-md text-red-800"
                      >
                        Archive
                      </button>
                      <button
                        onClick={() => sendMatch(match._id)}
                        className="underline ml-2 rounded-md text-blue-800"
                      >
                        Send
                      </button>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        <a
          href={`${constants.BACKEND_API_ENDPOINT}/internal/requestAnotherMatch?phone_number=${selectedUser.phone_number}`}
          target="_blank" rel="noreferrer"
        >
          <a href={`/matches/${id}`} target="_blank" rel="noreferrer">
            <button
              className="border border-emerald-800 text-emerald-800 p-2 rounded-md my-4 mr-2"
              // onClick={handleRequestAnotherMatch}
            >
              Find More Matches
            </button>
          </a>
        </a>
        <button
          onClick={() => generateMatch(selectedUser.phone_number)}
          className="bg-emerald-800 text-white p-2 rounded-md my-4"
        >
          Generate Match
        </button>
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Onboarding</h2>
          <p className="font-medium">Bio:</p>
          <p>{selectedUser.bio}</p>
          <p className="font-medium my-4">Looking For:</p>
          {selectedUser.looking_for &&
            selectedUser.looking_for
              .split("\n")
              .map((line, index) => (
                <li key={index}>{line.replace("-", "")}</li>
              ))}

          <h3 className="text-lg font-semibold mt-4 mb-2">
            Fit Finder Response
          </h3>
          <h3 className="text-lg font-semibold mt-4 mb-2">
            Questionnaire Response
          </h3>
          {selectedUser.questionnaire_response ? (
            <div className="bg-gray-100 p-4 rounded-md">
              {Object.entries(selectedUser.questionnaire_response).map(
                ([key, value]) => (
                  <div key={key} className="mb-2">
                    <span className="font-medium">{key}: </span>
                    <span>{JSON.stringify(value)}</span>
                  </div>
                )
              )}
            </div>
          ) : (
            <p>No questionnaire response available.</p>
          )}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content min-w-[1000px] bg-slate-200">
            <label>
              Note:
              <textarea
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="w-full h-[200px]"
              />
            </label>
            <label>
              Bio:
              <textarea
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                className="w-full h-[200px]"
              />
            </label>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleSaveChanges}
                className="bg-emerald-800 text-white p-2 rounded-md"
              >
                Save Changes
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-500 text-white p-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(UserDetailsPage, {
  returnTo: "/",
  onRedirecting: () => <UserDetailsPage />,
});
