import React, { useRef, useEffect, useState } from 'react';
import { Send, MoreVertical } from 'lucide-react';
import { Contact, Message } from '../types';
import ChatHeader from './ChatHeader';
import ChatMessage from './ChatMessage';
import ProfileSidebar from './ProfileSidebar';

interface ChatWindowProps {
  contact: Contact | null;
  messages: Message[];
  onSendMessage: (content: string) => void;
  onLoadMoreMessages: () => Promise<void>;
  onUpdateContact: (updates: Partial<Contact>) => void;
  onToggleMessageRead: (messageId: string) => void;
  onMarkConversationUnread: (contactId: string) => void;
  isLoading: boolean;
  isSendingMessage: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  contact,
  messages,
  onSendMessage,
  onLoadMoreMessages,
  onUpdateContact,
  onToggleMessageRead,
  onMarkConversationUnread,
  isLoading,
  isSendingMessage,
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  // const handleScroll = async () => {
  //   const container = messagesContainerRef.current;
  //   if (container && !isLoading && container.scrollTop === 0) {
  //     setIsLoading(true);
  //     await onLoadMoreMessages();
  //     setIsLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   const container = messagesContainerRef.current;
  //   if (container) {
  //     container.addEventListener('scroll', handleScroll);
  //     return () => container.removeEventListener('scroll', handleScroll);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newMessage.trim()) {
      onSendMessage(newMessage);
      setNewMessage('');
    }
  };

  if (!contact) {
    return (
      <div className="flex-1 flex items-center justify-center bg-gray-50">
        <p className="text-gray-500">Select a contact to start messaging</p>
      </div>
    );
  }

  return (
    <div className="flex-1 flex h-full">
      <div className="flex-1 flex flex-col h-full">
        <div className="flex justify-between items-center border-b border-gray-200">
          <ChatHeader
            contact={contact}
            onToggleProfile={() => setIsProfileOpen(!isProfileOpen)}
          />
          {/* <div className="relative" ref={optionsRef}>
            <button
              onClick={() => setShowOptions(!showOptions)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <MoreVertical className="h-5 w-5 text-gray-600" />
            </button>
            {showOptions && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-10">
                <button
                  onClick={() => {
                    if (contact) {
                      onMarkConversationUnread(contact.id);
                      setShowOptions(false);
                    }
                  }}
                  className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
                >
                  Mark as unread
                </button>
              </div>
            )}
          </div> */}
        </div>
        
        <div
          ref={messagesContainerRef}
          className="flex-1 overflow-y-auto p-4 space-y-4"
        >
          {isLoading ? (
            <div className="text-center py-2">
              <span className="text-gray-500">Loading messages...</span>
            </div>
          ) : (
            messages.map((message, index) => (
              <ChatMessage
                key={message.timestamp.toString()}
                message={message}
                ref={index === messages.length - 1 ? lastMessageRef : undefined}
                onToggleRead={() => onToggleMessageRead(message.id)}
              />
            ))
          )}
        </div>

        <form onSubmit={handleSubmit} className="p-4 border-t border-gray-200">
          <div className="flex space-x-4">
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
              className="flex-1 border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isSendingMessage}
            />
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              disabled={isSendingMessage}
            >
              {isSendingMessage ? (
                <div className="animate-spin rounded-full h-5 w-5 border-2 border-white border-t-transparent" />
              ) : (
                <Send className="h-5 w-5" />
              )}
            </button>
          </div>
        </form>
      </div>

      <ProfileSidebar
        contact={contact}
        isOpen={isProfileOpen}
        onClose={() => setIsProfileOpen(false)}
        onUpdateProfile={(updates) => onUpdateContact({ ...contact, profile: updates })}
      />
    </div>
  );
};

export default ChatWindow;