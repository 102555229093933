import React, { forwardRef } from 'react';
import { Check } from 'lucide-react';
import { Message } from '../types';

interface ChatMessageProps {
  message: Message;
  onToggleRead?: () => void;
}

const ChatMessage = forwardRef<HTMLDivElement, ChatMessageProps>(
  ({ message, onToggleRead }, ref) => {
    const isCustomer = message.sender === 'user';
    const formattedDate = new Date(message.timestamp).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });

    return (
      <div
        ref={ref}
        className={`flex ${isCustomer ? 'justify-start' : 'justify-end'} mb-4`}
      >
        <div
          className={`max-w-[70%] rounded-lg px-4 py-2 ${
            isCustomer ? 'bg-gray-100 text-gray-900' : 'bg-blue-500 text-white'
          }`}
        >
          <p>{message.content}</p>
          <div className="flex items-center justify-between mt-1 text-xs opacity-75">
            <div className="flex items-center space-x-2">
              <span>{formattedDate}</span>
              {!isCustomer && message.isDelivered && (
                <span className="flex">
                  <Check className="h-3 w-3" />
                  {message.isRead && <Check className="h-3 w-3 -ml-1" />}
                </span>
              )}
            </div>
            {!isCustomer && (
              <span className="text-xs opacity-75 ml-2">
                {message.isAiGenerated ? 'AI Generated' : 'Human Generated'}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
);

ChatMessage.displayName = 'ChatMessage';

export default ChatMessage;