import React, { useState } from 'react';
import { Contact, MatchmakingProfile, Match } from '../types';

interface ProfileSidebarProps {
  contact: Contact;
  isOpen: boolean;
  onClose: () => void;
  onUpdateProfile: (profile: MatchmakingProfile) => void;
}

const ProfileSidebar: React.FC<ProfileSidebarProps> = ({
  contact,
  isOpen,
  onClose,
  onUpdateProfile,
}) => {
  const [editedNote, setEditedNote] = useState('');
  const [editedLookingFor, setEditedLookingFor] = useState(contact.profile.lookingFor);
  const [isEditingLookingFor, setIsEditingLookingFor] = useState(false);

  if (!isOpen) return null;

  const handleAddNote = () => {
    if (!editedNote.trim()) return;

    const newNote = {
      id: `note-${Date.now()}`,
      content: editedNote,
      author: 'Current User',
      timestamp: new Date(),
    };

    onUpdateProfile({
      ...contact.profile,
      notes: [newNote, ...contact.profile.notes],
    });

    setEditedNote('');
  };

  const handleUpdateLookingFor = () => {
    onUpdateProfile({
      ...contact.profile,
      lookingFor: editedLookingFor,
    });
    setIsEditingLookingFor(false);
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const acceptedMatches = contact.profile.matchStats.recentMatches.filter(
    match => match.status === 'Accepted'
  );

  return (
    <div className="w-96 h-full border-l border-gray-200 bg-white overflow-y-auto">
      <div className="sticky top-0 bg-white border-b border-gray-200 p-4 flex justify-between items-center">
        <h2 className="text-xl font-semibold">Profile Details</h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700"
        >
          ×
        </button>
      </div>

      <div className="p-4 space-y-6">
        {/* Membership Details */}
        <section>
          <h3 className="text-lg font-medium mb-2">Membership</h3>
          <div className="bg-gray-50 p-4 rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <span className="font-medium">{contact.profile.membershipTier}</span>
              <span className="text-sm text-gray-500">
                {formatCurrency(contact.profile.membershipDetails.monthlyFee)}/month
              </span>
            </div>
            <div className="text-sm text-gray-600">
              <p>Subscribed: {new Date(contact.profile.membershipDetails.subscriptionDate).toLocaleDateString()}</p>
              <p>Expires: {new Date(contact.profile.membershipDetails.endDate).toLocaleDateString()}</p>
            </div>
            <div className="text-sm text-gray-600">
              <h4 className="font-medium mb-1">Benefits:</h4>
              <ul className="list-disc list-inside">
                {contact.profile.membershipDetails.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
            <div className="space-y-2">
              <h4 className="font-medium text-sm">Recent Transactions:</h4>
              {contact.profile.membershipDetails.recentTransactions.map((transaction) => (
                <div key={transaction.id} className="text-sm bg-white p-2 rounded border border-gray-200">
                  <div className="flex justify-between">
                    <span>{transaction.description}</span>
                    <span className="font-medium">{formatCurrency(transaction.amount)}</span>
                  </div>
                  <div className="text-xs text-gray-500 mt-1">
                    {new Date(transaction.date).toLocaleDateString()}
                  </div>
                </div>
              ))}
            </div>
            <a
              href={contact.externalLinks.squareCustomerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="block text-blue-500 hover:text-blue-600 text-sm"
            >
              Manage Membership →
            </a>
          </div>
        </section>

        {/* Looking For Section */}
        <section>
          <div className="flex justify-between items-center mb-2">
            <h3 className="text-lg font-medium">Looking For</h3>
            <button
              onClick={() => {
                if (isEditingLookingFor) {
                  handleUpdateLookingFor();
                } else {
                  setIsEditingLookingFor(true);
                }
              }}
              className="text-blue-500 text-sm hover:text-blue-600"
            >
              {isEditingLookingFor ? 'Save' : 'Edit'}
            </button>
          </div>
          {isEditingLookingFor ? (
            <textarea
              value={editedLookingFor}
              onChange={(e) => setEditedLookingFor(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
              rows={4}
            />
          ) : (
            <p className="text-gray-700">{contact.profile.lookingFor}</p>
          )}
        </section>

        {/* Match Statistics */}
        <section>
          <h3 className="text-lg font-medium mb-2">Match Statistics</h3>
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="flex justify-between items-center mb-4">
              <p>Total Matches: {contact.profile.matchStats.totalMatches}</p>
              {acceptedMatches.length > 0 && (
                <a
                  href={`https://matches.example.com/introductions/${contact.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600 text-sm"
                >
                  View All Introductions →
                </a>
              )}
            </div>
            <h4 className="font-medium mb-1">Recent Matches:</h4>
            <div className="space-y-2">
              {contact.profile.matchStats.recentMatches.map((match) => (
                <div
                  key={match.id}
                  className="bg-white p-2 rounded border border-gray-200"
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium">{match.name}</span>
                    <span className={`px-2 py-1 rounded text-xs ${
                      match.status === 'Accepted' ? 'bg-green-100 text-green-800' :
                      match.status === 'Declined' ? 'bg-red-100 text-red-800' :
                      'bg-yellow-100 text-yellow-800'
                    }`}>
                      {match.status}
                    </span>
                  </div>
                  <div className="mt-2 flex justify-between items-center text-sm">
                    <span className="text-gray-500">
                      {new Date(match.date).toLocaleDateString()}
                    </span>
                    {match.status === 'Accepted' && (
                      <a
                        href={match.introductionTrackerUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:text-blue-600"
                      >
                        View Introduction →
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* External Links */}
        <section>
          <h3 className="text-lg font-medium mb-2">External Links</h3>
          <div className="space-y-3">
            <div className="flex flex-col space-y-2">
              <a
                href={contact.externalLinks.crmProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600"
              >
                View in CRM →
              </a>
              <a
                href={contact.externalLinks.zendeskProfileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-600"
              >
                View in Zendesk →
              </a>
              {contact.externalLinks.linkedinProfileUrl && (
                <a
                  href={contact.externalLinks.linkedinProfileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600"
                >
                  LinkedIn Profile →
                </a>
              )}
              {contact.externalLinks.instagramProfileUrl && (
                <a
                  href={contact.externalLinks.instagramProfileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Instagram Profile →
                </a>
              )}
            </div>
            <div>
              <h4 className="font-medium mb-2">Support Tickets:</h4>
              {contact.externalLinks.zendeskTickets.map((ticket) => (
                <a
                  key={ticket.id}
                  href={ticket.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block mb-2 p-2 bg-white rounded-lg border border-gray-200 hover:bg-gray-50"
                >
                  <div className="flex justify-between items-center">
                    <span className="text-sm">{ticket.title}</span>
                    <span className={`px-2 py-1 rounded text-xs ${
                      ticket.status === 'Open' ? 'bg-red-100 text-red-800' :
                      ticket.status === 'Pending' ? 'bg-yellow-100 text-yellow-800' :
                      'bg-green-100 text-green-800'
                    }`}>
                      {ticket.status}
                    </span>
                  </div>
                  <div className="text-xs text-gray-500 mt-1">
                    Priority: {ticket.priority}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>

        {/* Notes section */}
        <section className="space-y-2">
          <h3 className="text-lg font-medium">Notes (Internal)</h3>
          <div className="space-y-2">
            <div className="flex space-x-2">
              <input
                type="text"
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
                placeholder="Add an internal note..."
                className="flex-1 border border-gray-300 rounded-lg px-3 py-2"
              />
              <button
                onClick={handleAddNote}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                Add
              </button>
            </div>
            <div className="space-y-2">
              {contact.profile.notes.map((note) => (
                <div key={note.id} className="p-3 bg-gray-50 rounded-lg">
                  <p className="text-gray-700">{note.content}</p>
                  <div className="mt-2 text-sm text-gray-500 flex justify-between">
                    <span>{note.author}</span>
                    <span>{new Date(note.timestamp).toLocaleString()}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProfileSidebar;