import React from 'react';
import { Star } from 'lucide-react';
import { Contact } from '../types';
import { formatMessageTimestamp, formatPhoneNumber } from '../../../components/utils';

interface ContactCardProps {
  contact: Contact;
  isSelected: boolean;
  onClick: () => void;
  onToggleStar: () => void;
}

const ContactCard: React.FC<ContactCardProps> = ({
  contact,
  isSelected,
  onClick,
  onToggleStar,
}) => {
  return (
    <div
      className={`p-4 border-b border-gray-200 cursor-pointer hover:bg-gray-50 ${
        isSelected ? 'bg-blue-50' : ''
      }`}
    >
      <div className="flex items-center space-x-4">
        <div className="relative">
          <img
            src={contact.avatar}
            alt={contact.name}
            className="w-12 h-12 rounded-full object-cover"
            onError={(e) => {
              e.currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";
            }}
          />
          {/* <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleStar();
            }}
            className="absolute -top-1 -right-1 p-1 rounded-full bg-white shadow-sm hover:bg-gray-100"
          >
            <Star
              className={`h-4 w-4 ${contact.isStarred ? 'fill-yellow-400 text-yellow-400' : 'text-gray-400'}`}
            />
          </button> */}
        </div>
        <div className="flex-1 min-w-0" onClick={onClick}>
          <div className="flex justify-between items-start">
            <h3 className="text-sm font-medium text-gray-900 truncate">
              {contact.name}
            </h3>
            {contact.lastMessage && (
              <span className="text-xs text-gray-500">
                {formatMessageTimestamp(contact.lastMessage.timestamp)}
              </span>
            )}
          </div>
          <p className="text-sm text-gray-500 truncate">
            {formatPhoneNumber(contact.phoneNumber)}
          </p>
          {contact.lastMessage && (
            <p className="text-sm text-gray-600 truncate">
              {contact.lastMessage.content}
            </p>
          )}
        </div>
        {contact.unreadCount > 0 && (
          <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-blue-500 text-white text-xs">
            {contact.unreadCount}
          </span>
        )}
      </div>
    </div>
  );
};

export default ContactCard;