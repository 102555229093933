
export function formatPhoneNumber(phoneNumber: string) {
  return phoneNumber.replace(/(\+\d)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
}


export function formatMessageTimestamp(timestamp: string | number | Date): string {
  const messageDate = new Date(timestamp);
  const today = new Date();
  const isToday = messageDate.toDateString() === today.toDateString();
  
  return isToday
    ? messageDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })
    : messageDate.toLocaleDateString([], {
        month: 'short',
        day: 'numeric',
      });
}