import React from 'react';
import { Search, Star } from 'lucide-react';

interface ContactListHeaderProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
  showStarredOnly: boolean;
  onToggleStarredFilter: () => void;
}

const ContactListHeader: React.FC<ContactListHeaderProps> = ({
  searchQuery,
  onSearchChange,
  showStarredOnly,
  onToggleStarredFilter,
}) => {
  return (
    <div className="p-4 border-b border-gray-200 space-y-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Search by name or phone number..."
          className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchQuery}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>
      {/* <button
        onClick={onToggleStarredFilter}
        className={`flex items-center space-x-2 px-3 py-1.5 rounded-lg transition-colors ${
          showStarredOnly ? 'bg-yellow-100 text-yellow-700' : 'bg-gray-100 text-gray-600'
        }`}
      >
        <Star className={`h-4 w-4 ${showStarredOnly ? 'fill-yellow-400' : ''}`} />
        <span className="text-sm font-medium">
          {showStarredOnly ? 'Show All' : 'Show Starred'}
        </span>
      </button> */}
    </div>
  );
}

export default ContactListHeader;