import React, { useState, useCallback, useEffect } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';

import ContactList from './components/ContactList';
import ContactListHeader from './components/ContactListHeader';
import ChatWindow from './components/ChatWindow';
import { Contact, Message } from './types';
import useSMS from '../../hooks/useSMS';

function Sms() {
  const {
    contacts,
    loadingContacts,
    error,
    searchContacts,
    messages,
    getMessages,
    loadingMessages,
    sendMessage,
    sendingMessage
  } = useSMS();
  
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [reloadingMessages, setReloadingMessages] = useState(false);
  const [showStarredOnly, setShowStarredOnly] = useState(false);

  useEffect(() => {
    searchContacts("");
  }, []);

  useEffect(() => {
    if (selectedContact) {
      getMessages(selectedContact.id);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (!selectedContact) return;

    const pollInterval = setInterval(() => {
      if (reloadingMessages) return;
      setReloadingMessages(true);
      getMessages(selectedContact.id, false);
      setReloadingMessages(false);
    }, 7000);

    return () => clearInterval(pollInterval);
  }, [selectedContact]);

  const handleContactSelect = (contact: Contact) => {
    setSelectedContact(contact);
    setCurrentPage(1);
    // Load initial messages for selected contact
    // setMessages(mockMessages[contact.id] || []);
    // Mark messages as read
    // setContacts(contacts.map(c => 
    //   c.id === contact.id ? { ...c, unreadCount: 0 } : c
    // ));
  };

  const handleLoadMoreMessages = async () => {
    if (!selectedContact) return;
    
    // // In a real app, this would be an API call with pagination
    // const nextPage = currentPage + 1;
    // const start = messages.length;
    // const end = start + MESSAGES_PER_PAGE;
    
    // // Simulate API delay
    // await new Promise(resolve => setTimeout(resolve, 1000));
    
    // const moreMessages = mockMessages[selectedContact.id]?.slice(start, end) || [];
    // if (moreMessages.length > 0) {
    //   // setMessages(prev => [...moreMessages, ...prev]);
    //   setCurrentPage(nextPage);
    // }
  };

  const handleSendMessage = useCallback((content: string) => {
    if (!selectedContact) return;

    sendMessage(selectedContact.id, content);
    // const newMessage: Message = {
    //   id: `m${Date.now()}`,
    //   content,
    //   timestamp: new Date(),
    //   sender: 'user',
    //   isAiGenerated: false,
    //   isRead: false,
    //   isDelivered: true,
    // };

    // setMessages(prev => [...prev, newMessage]);
    
    // Update last message in contacts
    // setContacts(contacts.map(c =>
    //   c.id === selectedContact.id ? { ...c, lastMessage: newMessage } : c
    // ));

    // Simulate AI response if enabled
    // if (selectedContact.aiMessagingEnabled) {
    //   setTimeout(() => {
    //     const aiResponse: Message = {
    //       id: `m${Date.now()}`,
    //       content: 'This is an AI-generated response.',
    //       timestamp: new Date(),
    //       sender: 'customer',
    //       isAiGenerated: true,
    //       isRead: false,
    //       isDelivered: true,
    //     };
    //     setMessages(prev => [...prev, aiResponse]);
    //   }, 1000);
    // }
  }, [selectedContact]);

  const handleUpdateContact = useCallback((updates: Partial<Contact>) => {
    if (!selectedContact) return;
    
    const updatedContact = { ...selectedContact, ...updates };
    setSelectedContact(updatedContact);
    // setContacts(contacts.map(c =>
    //   c.id === selectedContact.id ? updatedContact : c
    // ));
  }, [selectedContact, contacts]);

  const handleToggleStar = useCallback((contactId: string) => {
    // setContacts(contacts.map(c =>
    //   c.id === contactId ? { ...c, isStarred: !c.isStarred } : c
    // ));
  }, [contacts]);

  const handleToggleMessageRead = useCallback((messageId: string) => {
    // setMessages(messages.map(m =>
    //   m.id === messageId ? { ...m, isRead: !m.isRead } : m
    // ));
  }, [messages]);

  const handleMarkConversationUnread = useCallback((contactId: string) => {
    // setContacts(contacts.map(c =>
    //   c.id === contactId ? { ...c, unreadCount: 1 } : c
    // ));
  }, [contacts]);

  const filteredContacts = contacts.filter(contact =>
    // (!showStarredOnly || contact.isStarred) &&
    (contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    contact.phoneNumber.includes(searchQuery))
  );

  return (
    <div className="flex h-screen bg-white">
      <div className="w-80 border-r border-gray-200 flex flex-col">
        <ContactListHeader
          searchQuery={searchQuery}
          onSearchChange={setSearchQuery}
          showStarredOnly={showStarredOnly}
          onToggleStarredFilter={() => setShowStarredOnly(!showStarredOnly)}
        />
        {loadingContacts ? (
          <div className="flex items-center justify-center flex-1">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900" />
          </div>
        ) : (
          <ContactList
            contacts={filteredContacts}
            selectedContactId={selectedContact?.id || null}
            onContactSelect={handleContactSelect}
            onToggleStar={handleToggleStar}
          />
        )}
      </div>
      <ChatWindow
        contact={selectedContact}
        messages={messages}
        isLoading={loadingMessages}
        onSendMessage={handleSendMessage}
        isSendingMessage={sendingMessage}
        onLoadMoreMessages={handleLoadMoreMessages}
        onUpdateContact={handleUpdateContact}
        onToggleMessageRead={handleToggleMessageRead}
        onMarkConversationUnread={handleMarkConversationUnread}
      />
    </div>
  );
}

export default withAuthenticationRequired(Sms, {
  returnTo: "/",
  onRedirecting: () => <Sms />,
});
